import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import ApolloClient from "apollo-boost";
import { ApolloProvider } from "@apollo/react-hooks";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import BookingLookup from "./components/bookingLookup";
import BookingView from "./components/bookingView";

import { IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import introspectionQueryResultData from "./fragmentTypes.json";
import { InMemoryCache } from "apollo-cache-inmemory";
import { BaseStyles } from "./themes/base";
import "whatwg-fetch";

import "lightboot";
import "./mobile.css";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

function App({ gqlUrl, token, classes, favicon }) {
  document.getElementById("favicon").setAttribute("href", favicon);

  const cache = new InMemoryCache({ fragmentMatcher });

  const client = new ApolloClient({
    uri: gqlUrl,
    headers: { authorization: `Bearer ${token}` },
    cache: cache,
  });

  return (
    <div className={classes.bg}>
      <AppBar className={classes.appBar} position="static">
        <Toolbar>
          <div className={classes.logo}></div>
        </Toolbar>
      </AppBar>

      <BookingLookup>
        <ApolloProvider client={client}>
          <BookingView></BookingView>
        </ApolloProvider>
      </BookingLookup>
    </div>
  );
}

export default connect((state) => ({
  token: state.requestAuth.token,
  gqlUrl: state.requestAuth.gqlUrl,
}))(withStyles(BaseStyles)(App));
