import { createMuiTheme } from "@material-ui/core/styles";
import background from "./bg.jpg";
import logo from "./logo.png";

const theme = () => {
  return createMuiTheme({
    bg: `url(${background})`,
    appBarColor: "#02AFAE",
    inputColor: "#02AFAE",
    logo: {
      backgroundImage: `url(${logo})`,
      backgroundSize: "100% 100%",
      backgroundRepeat: "no-repeat",
      width: 202,
      height: 84,
      margin: 5,
    },
    pageTitle: {
      marginTop: 20,
      marginBottom: 20,
      fontSize: "2.3rem",
      backgroundColor: "#F1A806",
      color: "white",
      padding: 5,
      paddingLeft: 20,
      paddingRight: 20,
      display: "inline-block",
    },
    pageTitleAlt: {
      color: "#FFF",
    },
    card: {
      backgroundColor: "#fff",
      position: "relative",
      padding: 20,
      paddingTop: 60,
      margin: 30,
      marginTop: 50,
      border: "solid 1px #eee",
      borderRadius: 5,
    },
    cardTitle: {
      backgroundColor: "#02AFAE",
      color: "white",
      position: "absolute",
      top: "-30px",
      paddingLeft: 10,
      paddingRight: 10,
    },
    cardTitleHighlight: {
      color: "#FFF",
    },
    cardTitleText: {
      fontSize: "1.8rem",
    },

    cardAltTitle: {
      backgroundColor: "#F1A806",
      color: "white",
      position: "absolute",
      top: "-30px",
      paddingLeft: 10,
      paddingRight: 10,
    },
    cardAltTitleText: {
      fontSize: "2.4rem",
    },
  });
};

export default theme;
