import React, { Fragment, useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";

const ProgressIndicator = ({ children, visible, title }) => {
  const [dialogVisible, setDialogVisible] = useState(false);

  useEffect(() => {
    if (visible === true) {
      setDialogVisible(visible);
      return;
    }
    const cancel = setTimeout(function () {
      setDialogVisible(visible);
    }, 200);
    return () => {
      clearTimeout(cancel);
    };
  }, [visible]);

  return (
    <Fragment>
      <Dialog
        open={dialogVisible}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          {title || "One moment.."}
        </DialogTitle>
        <DialogContent>
          <div style={{ textAlign: "center", marginBottom: 20 }}>
            <CircularProgress color="primary" />
          </div>
        </DialogContent>
      </Dialog>

      {dialogVisible === false && children}
    </Fragment>
  );
};

export default ProgressIndicator;
