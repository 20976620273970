import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const FlightPicker = ({
  destination,
  data,
  open,
  onDismiss,
  onFlightSelected,
}) => {
  return (
    <Dialog
      open={open}
      fullWidth={true}
      onClose={onDismiss}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" onClose={onDismiss}>
        Select Flight
      </DialogTitle>

      <DialogContent style={{ height: "60vh" }}>
        {data.flights.length === 0 && <Typography>No flights found</Typography>}

        <List>
          {data.flights.map((item, key) => {
            const expectedLandingAt = new Date(item.expectedLandingAt);

            return (
              <ListItem
                style={{
                  border: "solid 1px lightgray",
                  borderRadius: 5,
                  marginBottom: 5,
                  cursor: "pointer",
                }}
                key={key}
                onClick={() => {
                  onFlightSelected(item);
                }}
              >
                <ListItemText
                  primary={
                    <div>
                      <b>{item.designator}</b>{" "}
                      <span>{expectedLandingAt.toLocaleTimeString()}</span>{" "}
                      <span>{expectedLandingAt.toLocaleDateString()}</span>
                    </div>
                  }
                  secondary={`${item.arrivalTerminal || ""}${destination}`}
                />
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export { FlightPicker };
