import React from "react";
import props from "./prop-types";

import { Typography, Badge, withStyles } from "@material-ui/core";
import { Fader } from "../fader";
import { BaseStyles } from "../../themes/base";

const CardWithLabel = ({
  title,
  children,
  badge,
  visible,
  classes,
  altTitle,
}) => {
  if (visible === null || visible === undefined) {
    visible = true;
  }

  return (
    <Fader in={visible}>
      <div className={classes.card}>
        <div className={altTitle === true ? "scale-6" : "scale-9"}>
          <div
            className={
              altTitle === true ? classes.cardAltTitle : classes.cardTitle
            }
          >
            <Typography
              className={
                altTitle === true
                  ? classes.cardAltTitleText
                  : classes.cardTitleText
              }
            >
              {badge > 0 && (
                <Badge
                  badgeContent={badge}
                  color="secondary"
                  style={{ marginRight: 20 }}
                ></Badge>
              )}

              {title}
            </Typography>
          </div>
        </div>
        {children}
      </div>
    </Fader>
  );
};

CardWithLabel.propTypes = props;

export default withStyles(BaseStyles)(CardWithLabel);
