import React, { useState, useEffect } from "react";
import CardWithLabel from "../../components/cardWithLabel";
import { TextField, Button } from "@material-ui/core";

import { FlightDestinationPicker } from "../../components/dialogs/flightDestinationPicker";
import { FutureTimeDatePicker } from "../../components/dialogs/futureTimeDatePicker";
import { FlightPicker } from "../../components/dialogs/flightPicker";

import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import ProgressIndicator from "../../components/progressIndicator";

import destinations from "../../destinations.json";

const Dialogs = {
  none: 0,
  ReturnDate: 1,
  Destination: 2,
};

const FlightLookup = ({
  classes,
  bookingData,
  onBookingDataChanged,
  errorField,
  triggerLookup,
}) => {
  const [fetchFlightData, { loading, data, error }] = useLazyQuery(gql`
    query(
      $expectedArrivalAt: DateTime!
      $designator: String
      $departureAirport: String
    ) {
      flights: searchFlights(
        expectedArrivalAt: $expectedArrivalAt
        designator: $designator
        departureAirport: $departureAirport
      ) {
        ... on Flight {
          designator
          expectedLandingAt
          arrivalTerminal
          departureAirport {
            name
            iataCode
          }
          arrivalAirport {
            name
            iataCode
          }
        }
      }
    }
  `);

  useEffect(() => {
    if (data !== undefined && loading === false && error == null) {
    } else if (error != null) {
    }
  }, [data, loading, error]);

  const [dialog, setDialog] = useState(Dialogs.none);

  let flightReturnDateText = "";

  if (bookingData.flightReturnDate != null) {
    flightReturnDateText = `${bookingData.flightReturnDate.toLocaleDateString()} ${bookingData.flightReturnDate.toLocaleTimeString()}`;
  }

  let flightDestinationIata = bookingData.flightDestination || "";

  let flightDestinationName = "";

  const getFlightDestinationName = () => {
    if (flightDestinationIata != null) {
      for (var i = 0; i < destinations.length; i++) {
        if (destinations[i].iata === flightDestinationIata) {
          flightDestinationName = destinations[i].name || "";
          break;
        }
      }
    }
  };

  getFlightDestinationName();

  const findFlightDetails = () => {
    setDialog(Dialogs.none);

    getFlightDestinationName();

    if (
      flightDestinationName.length > 0 &&
      bookingData.flightReturnDate != null
    ) {
      setDialog(Dialogs.FlightLookup);

      const lookupVariables = {
        expectedArrivalAt: bookingData.flightReturnDate.toISOString(),
        departureAirport: bookingData.flightDestination,
      };
      /*
      if ((bookingData.flightNumber || "").length > 0) {
        lookupVariables["designator"] = bookingData.flightNumber;
      }*/

      console.log(lookupVariables);

      fetchFlightData({
        variables: lookupVariables,
      });
    }
  };

  triggerLookup(() => {
    findFlightDetails();
  });

  return (
    <ProgressIndicator visible={loading} title="Searching flights..">
      {dialog === Dialogs.FlightLookup && (
        <FlightPicker
          data={data || { flights: [] }}
          destination={flightDestinationName || ""}
          open={true}
          onDismiss={() => {
            setDialog(Dialogs.none);
          }}
          onFlightSelected={(flight) => {
            const booking = Object.assign({}, bookingData);
            booking.flightData = flight;
            booking.flightNumber = flight.designator;
            booking.flightDestination = flight.departureAirport.iataCode;
            booking.flightReturnDate = new Date(flight.expectedLandingAt);
            onBookingDataChanged(booking);
            setDialog(Dialogs.none);
          }}
        ></FlightPicker>
      )}
      <div className="row">
        <div className="col-12 col-xl-6">
          <CardWithLabel title="Return Flight">
            {dialog === Dialogs.ReturnDate && (
              <FutureTimeDatePicker
                open={true}
                onDismiss={() => {
                  setDialog(Dialogs.none);
                }}
                error={errorField === "vehicleReg"}
                onDateSelected={(date) => {
                  const booking = Object.assign({}, bookingData);
                  booking.flightReturnDate = date;
                  booking.flightData = null;
                  onBookingDataChanged(booking);
                  setDialog(Dialogs.none);
                }}
              ></FutureTimeDatePicker>
            )}

            <FlightDestinationPicker
              classes={classes}
              open={dialog === Dialogs.Destination}
              onDismiss={() => {
                setDialog(Dialogs.none);
              }}
              onDestinationSelected={(destination) => {
                const booking = Object.assign({}, bookingData);
                booking.flightDestination = destination.iata;
                booking.flightData = null;
                onBookingDataChanged(booking);
                setDialog(Dialogs.none);
              }}
            ></FlightDestinationPicker>

            <TextField
              id="bk-flightReturnDate"
              label="Return Flight Date"
              aria-readonly={true}
              variant="outlined"
              error={errorField === "flightReturnDate"}
              value={flightReturnDateText}
              className={classes.input}
              onClick={() => {
                setDialog(Dialogs.ReturnDate);
              }}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,

                  disabled: classes.disabled,
                },
                readOnly: Boolean(true),
                disabled: Boolean(true),
              }}
            />
            <TextField
              id="bk-flightDestination"
              label="Flight Destination"
              error={errorField === "flightDestination"}
              aria-readonly={true}
              variant="outlined"
              value={flightDestinationName || ""}
              className={classes.input}
              onClick={() => {
                const booking = Object.assign({}, bookingData);

                booking.flightData = null;
                onBookingDataChanged(booking);

                setDialog(Dialogs.Destination);
              }}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,

                  disabled: classes.disabled,
                },
                readOnly: Boolean(true),
                disabled: Boolean(true),
              }}
            />

            <div className={classes.textRight}>
              <Button
                size="large"
                color="primary"
                variant="contained"
                onClick={findFlightDetails}
              >
                Find Flight
              </Button>
            </div>
          </CardWithLabel>
        </div>

        {bookingData.flightData != null && (
          <div className="col-12 col-xl-6">
            <CardWithLabel title="We found your flight">
              <TextField
                id="bk-flightNumber"
                label="Flight Number"
                variant="outlined"
                onClick={() => {
                  findFlightDetails();
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,

                    disabled: classes.disabled,
                  },
                  readOnly: Boolean(true),
                  disabled: Boolean(true),
                }}
                value={bookingData.flightNumber || ""}
                className={classes.input}
              />
            </CardWithLabel>
          </div>
        )}
      </div>
    </ProgressIndicator>
  );
};

export { FlightLookup };
