import React from "react";
import { connect } from "react-redux";
import PageCIWizard from "../../pages/ci-wizard";
import ProgressIndicator from "../progressIndicator";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AlertDialog } from "../dialogs";
import store from "../../store";
import settings from "../../settings";

const BookingView = ({
  bookingReference,
  leadPassengerLastName,
  resetAuth,
}) => {
  const { loading, error, data } = useQuery(
    gql`
      query Booking(
        $bookingReference: String!
        $leadPassengerLastName: String!
      ) {
        booking: findBooking(
          leadPassengerLastName: $leadPassengerLastName
          reference: $bookingReference
        ) {
          ... on Booking {
            id
            reference
            passengerCount
            leadPassenger {
              title
              initial
              lastName
              phoneNumber
              emailAddress
            }
            returnFlight {
              designator
              expectedLandingAt
              departureAirport {
                iataCode
              }
            }
            vehicle {
              registration
              make
              model
              colour
            }
            qrCode {
              code
              dataImage
            }
          }
        }
      }
    `,
    {
      variables: {
        leadPassengerLastName,
        bookingReference,
      },
    }
  );

  if (error != null) {
    return (
      <AlertDialog
        title="An error has occured"
        message="Unable to fetch booking details"
        open={true}
        onDismiss={resetAuth}
      ></AlertDialog>
    );
  }

  let booking = null;

  if (loading === false) {
    data.booking.returnFlight = data.booking.returnFlight || {
      expectedLandingAt: null,
    };

    const flightReturnDate =
      data.booking.returnFlight.expectedLandingAt == null
        ? null
        : new Date(data.booking.returnFlight.expectedLandingAt);

    let destinationIata = null;

    if (data.booking.returnFlight != null) {
      if (data.booking.returnFlight.departureAirport != null) {
        destinationIata = data.booking.returnFlight.departureAirport.iataCode;
      }
    }

    console.log(data.booking);

    booking = {
      title: (data.booking.leadPassenger.title || "").trim(),
      initial: (data.booking.leadPassenger.initial || "").trim(),
      lastName: (data.booking.leadPassenger.lastName || "").trim(),

      email: (data.booking.leadPassenger.emailAddress || "").trim(),
      mobile: (data.booking.leadPassenger.phoneNumber || "").trim(),

      flightNumber: (data.booking.returnFlight.designator || "").trim(),
      flightDestination: destinationIata,
      flightReturnDate: flightReturnDate,
      flightData: null,
      pax: data.booking.passengerCount,
      vehicleReg: (data.booking.vehicle.registration || "").trim(),
      vehicleMake: (data.booking.vehicle.make || "").trim(),
      vehicleModel: (data.booking.vehicle.model || "").trim(),
      vehicleColour: (data.booking.vehicle.colour || "").trim(),
    };

    if (
      booking.vehicleReg.length > 0 &&
      booking.vehicleMake.length > 0 &&
      booking.vehicleModel.length > 0 &&
      booking.vehicleColour.length > 0
    ) {
      if (settings.forceVehicleReValidateExistingData === false) {
        //Vehicle detals already populated.. skip DVLA check for this booking
        booking.vehicleChecked = data.booking.vehicle.registration;
      }
    }
  }

  return (
    <ProgressIndicator visible={loading} title="Checking details..">
      {booking != null && <PageCIWizard bookingData={booking} />}
    </ProgressIndicator>
  );
};

export default connect(
  (state) => ({
    bookingReference: state.requestAuth.bookingReference,
    leadPassengerLastName: state.requestAuth.leadPassengerLastName,
  }),
  {
    resetAuth: store.triggers.requestAuth.reset,
  }
)(BookingView);
