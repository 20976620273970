import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import store from "./store";
import { Router, Switch, Route } from "react-router-dom";
import GoogleAnalytics from "react-ga";

import ThemeProvider from "@material-ui/styles/ThemeProvider";
import apsTheme from "./themes/aps";
import dsaTheme from "./themes/dsa";
import ediTheme from "./themes/edi";

const appStore = createStore(store.rootReducer, applyMiddleware(thunk));

GoogleAnalytics.initialize("UA-177432419-1");

const history = require("history").createBrowserHistory();

history.listen((location) => {
  GoogleAnalytics.set({ page: location.pathname });
  GoogleAnalytics.pageview(location.pathname);
});

ReactDOM.render(
  <Provider store={appStore}>
    <Router history={history}>
      <Switch>
        <Route path="/aps">
          <ThemeProvider theme={apsTheme()}>
            <App favicon={"/favicon-aps.ico"} />
          </ThemeProvider>
        </Route>
        <Route path="/dsa">
          <ThemeProvider theme={dsaTheme()}>
            <App favicon={"/favicon-dsa.ico"} />
          </ThemeProvider>
        </Route>
        <Route path="/edi">
          <ThemeProvider theme={ediTheme()}>
            <App favicon={"/favicon-edi.ico"} />
          </ThemeProvider>
        </Route>
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
