const BaseStyles = (theme) => {
  return {
    root: {
      textAlign: "center",
      flexGrow: 1,
    },
    bg: {
      background: theme.bg,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      position: "fixed",
      overflow: "auto",
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },

    logo: theme.logo,
    appBar: {
      marginBottom: 10,
      backgroundColor: theme.appBarColor,
    },
    pageContainer: {
      width: "100%",
      maxWidth: 1200,
      display: "inline-block",
      position: "relative",
      textAlign: "left",
      marginTop: 10,
      marginBottom: 100,
    },
    input: {
      color: "#24214A",
      width: "100%",
      marginBottom: "2rem",
    },
    cssLabel: {
      color: theme.inputColor,
      fontSize: 21,
      fontWeight: "bold",
    },
    cssOutlinedInput: {
      fontSize: 22,
      "&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline": {
        borderColor: theme.inputColor, //default
      },
      "&:hover:not($disabled):not($cssFocused):not($error) $notchedOutline": {
        borderColor: theme.inputColor, //hovered
      },
      "&$cssFocused $notchedOutline": {
        borderColor: theme.inputColor, //focused
      },
    },
    notchedOutline: {},
    cssFocused: {},
    error: {},
    disabled: {
      color: theme.inputColor,
    },
    subText: {
      paddingBottom: "1.5rem",
    },
    textRight: {
      textAlign: "right",
    },
    card: theme.card,
    cardTitle: theme.cardTitle,
    cardTitleHighlight: theme.cardTitleHighlight,
    cardTitleText: theme.cardTitleText,

    cardAltTitle: theme.cardAltTitle,
    cardAltTitleText: theme.cardAltTitleText,

    pageTitle: theme.pageTitle,
    pageTitleAlt: theme.pageTitleAlt,

    instructions: {
      "text-align": "center",
    },
    fabBar: {
      textAlign: "text-right",
      position: "fixed",
      bottom: "5%",
      right: "10%",
    },
    fab: {
      margin: "0.5rem",
    },
  };
};

export { BaseStyles };
