import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { Typography, Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ListItemText from "@material-ui/core/ListItemText";
import CloseIcon from "@material-ui/icons/Close";
import BackIcon from "@material-ui/icons/ArrowBack";
import MuiDialogTitle from "@material-ui/core/DialogTitle";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: 0,
    paddingTop: 10,
  },
  backButton: {
    position: "absolute",
    left: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const Steps = {
  dateMonth: 0,
  dateDay: 1,
  timeHour: 2,
  timeMinute: 3,
  finish: 4,
};

const Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const getDaysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate();
};

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, onBack, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onBack != null && (
        <IconButton
          aria-label="back"
          className={classes.backButton}
          onClick={onBack}
        >
          <BackIcon />
        </IconButton>
      )}

      <Typography style={{ textAlign: "center" }} variant="h6">
        {children}
      </Typography>

      {onClose != null && (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      )}
    </MuiDialogTitle>
  );
});

const FutureTimeDatePicker = ({ open, onDismiss, onDateSelected }) => {
  const [step, setStep] = useState(Steps.dateMonth);
  const [date, setDate] = useState(new Date().getTime());

  let items = [];
  let title = null;

  let applyDateMod = null;
  let buttonClass = "col-4 col-md-3";

  let currentDate = new Date(date);

  if (step === Steps.timeHour) {
    title = "Select Hour";

    for (let i = 0; i < 24; i++) {
      items.push({
        value: i,
        text: i.toString().padStart(2, "0") + ":--",
      });
    }

    applyDateMod = (item) => {
      currentDate.setHours(item.value);
    };
  } else if (step === Steps.timeMinute) {
    title = "Select Minute";
    for (let i = 0; i < 60; i += 5) {
      if (!(i === 0 && currentDate.getHours() === 0)) {
        // Don't allow 00:00
        items.push({
          value: i,
          text:
            currentDate.getHours().toString().padStart(2, "0") +
            ":" +
            i.toString().padStart(2, "0"),
        });
      }
    }
    applyDateMod = (item) => {
      currentDate.setMinutes(item.value);
      currentDate.setSeconds(0);
    };
  } else if (step === Steps.dateMonth) {
    buttonClass = "col-12 col-md-6";
    title = "Select Month";

    let mDate = new Date();

    for (let i = 0; i < 12; i++) {
      items.push({
        value: mDate.getMonth(),
        text: (
          <div>
            <div> {Months[mDate.getMonth()]}</div>
            <div> {mDate.getFullYear().toString()}</div>
          </div>
        ),
      });

      mDate.setMonth(mDate.getMonth() + 1);
    }
    applyDateMod = (item) => {
      currentDate.setMonth(item.value);
    };
  } else if (step === Steps.dateDay) {
    buttonClass = "col-4 col-md-2";
    title = "Select Day";

    const daysInMonth = getDaysInMonth(
      currentDate.getMonth() + 1,
      currentDate.getFullYear()
    );

    let startAt = 1;
    let now = new Date();

    if (
      currentDate.getFullYear() === now.getFullYear() &&
      currentDate.getMonth() === now.getMonth()
    ) {
      startAt = currentDate.getDate();
    }
    for (let i = startAt; i < daysInMonth + 1; i++) {
      items.push({
        value: i,
        text: i.toString(),
      });
    }
    applyDateMod = (item) => {
      currentDate.setDate(item.value);
    };
  }

  return (
    <Dialog
      open={open}
      fullWidth={true}
      onClose={onDismiss}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        onClose={onDismiss}
        onBack={
          step === Steps.timeHour
            ? null
            : () => {
                setStep(step - 1);
              }
        }
      >
        {title}
      </DialogTitle>

      <DialogContent style={{ height: "60vh" }}>
        <div className="row">
          {items.map((item, key) => {
            return (
              <div key={key} className={buttonClass}>
                <div
                  style={{ paddingLeft: 5, paddingRight: 5, paddingBottom: 5 }}
                >
                  <Button
                    style={{
                      width: "100%",
                      border: "solid 1px lightgray",

                      borderRadius: 5,

                      cursor: "pointer",
                    }}
                    onClick={() => {
                      const nextStep = step + 1;

                      applyDateMod(item);

                      setDate(currentDate.getTime());

                      if (nextStep === Steps.finish) {
                        onDateSelected(currentDate);
                      } else {
                        setStep(nextStep);
                      }
                    }}
                  >
                    <ListItemText primary={item.text} />
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export { FutureTimeDatePicker };
