import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

import PassengerIcon from "@material-ui/icons/Person";
import VehicleIcon from "@material-ui/icons/DirectionsCar";
import FlightIcon from "@material-ui/icons/FlightTakeoff";

import PageStepper from "../stepper/";
import { Fader } from "../../components/fader";

import { PassengerDetails } from "../steps/passenger";
import { VehicleLookup } from "../steps/vehicle";
import { FlightLookup } from "../steps/flight";
import settings from "../../settings";
import { AlertDialog } from "../../components/dialogs";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import ProgressIndicator from "../../components/progressIndicator";
import { BaseStyles } from "../../themes/base";

import PrintOut from "../steps/printout";

class Step extends React.Component {
  render() {
    return <div className="container">{this.props.children}</div>;
  }
}

const errorMessages = {
  BookingAlreadyCheckedInError:
    "It appears this booking has already been checked in.",
};

const PageCIWizard = ({ bookingData, classes }) => {
  const [updatePassenger, updatePassengerStatus] = useMutation(
    gql`
      mutation(
        $title: String!
        $initial: String!
        $lastName: String!
        $passengerCount: Int!
        $phoneNumber: String!
        $emailAddress: String!
      ) {
        confirmPassenger(
          input: {
            title: $title
            initial: $initial
            lastName: $lastName
            passengerCount: $passengerCount
            phoneNumber: $phoneNumber
            emailAddress: $emailAddress
          }
        ) {
          result {
            __typename
          }
        }
      }
    `,
    { onError: (err) => console.warn(err) }
  );
  const [updateVehicle, updateVehicleStatus] = useMutation(
    gql`
      mutation(
        $registration: String!
        $make: String!
        $model: String!
        $colour: String!
      ) {
        confirmVehicle(
          input: {
            registration: $registration
            make: $make
            model: $model
            colour: $colour
          }
        ) {
          result {
            __typename
          }
        }
      }
    `,
    { onError: (err) => console.warn(err) }
  );
  const [updateFlight, updateFlightStatus] = useMutation(
    gql`
      mutation($designator: String!, $expectedLandingAt: DateTime!) {
        confirmReturnFlight(
          input: {
            designator: $designator
            expectedLandingAt: $expectedLandingAt
          }
        ) {
          result {
            __typename
          }
        }
      }
    `,
    { onError: (err) => console.warn(err) }
  );
  const [confirmOnlineCheckIn, confirmOnlineCheckInStatus] = useMutation(
    gql`
      mutation {
        completeOnlineCheckIn {
          __typename
          result {
            __typename
            ... on Booking {
              __typename
              reference
              qrCode {
                code
                dataImage
              }
            }
            ... on PassengerIncompleteError {
              __typename
              message
            }
            ... on ReturnFlightIncompleteError {
              __typename
              message
            }
            ... on VehicleIncompleteError {
              __typename
              message
            }
          }
        }
      }
    `,
    { onError: (err) => console.warn(err) }
  );

  const [pageState, setPageState] = useState({
    bookingData: bookingData,
    bannerText: (
      <Typography className={classes.pageTitle}>
        We just need a few <span className={classes.pageTitleAlt}>details</span>
      </Typography>
    ),
  });
  const [errorState, setErrorState] = useState({
    errorField: null,
    errorTitle: null,
    errorMessage: null,
  });

  const onBookingDataChanged = (data) => {
    let state = Object.assign({}, pageState);
    state.bookingData = data;
    setPageState(state);
    setErrorState({ errorField: null, errorMessage: null });
  };

  let vehicleLookupTrigger = () => {};
  const attachVehicleLookupTrigger = (trigger) => {
    vehicleLookupTrigger = trigger;
  };
  let flightLookupTrigger = () => {};
  const attachFlightLookupTrigger = (trigger) => {
    flightLookupTrigger = trigger;
  };

  const onStepChanged = (stepChild) => {
    if (stepChild) {
      let state = Object.assign({}, pageState);
      state.bannerText = stepChild.props.bannerText;

      setPageState(state);
      setErrorState({ errorField: null, errorMessage: null });
    }
  };

  const validateFlightDetails = () => {
    let flightReturnDate = pageState.bookingData.flightReturnDate || "";

    if (flightReturnDate.length === 0) {
      setErrorState({
        errorField: "flightReturnDate",
        errorMessage: "Select your return flight date",
      });
      return false;
    }
    let flightDestination = pageState.bookingData.flightDestination || "";

    if (flightDestination.length === 0) {
      setErrorState({
        errorField: "flightDestination",
        errorMessage: "Select the flight destination",
      });
      return false;
    }

    return true;
  };

  const onFinalStep = () => {
    const bannerText = (
      <Typography className={classes.pageTitle}>
        Thank you for confirming your
        <span className={classes.pageTitleAlt}> details</span>
      </Typography>
    );
    setPageState({
      ...pageState,
      bannerText,
    });
  };

  const onStepNext = async (index, stepChild) => {
    setErrorState({ errorField: null, errorMessage: null });

    if (
      index === settings.vehicleLookupStepIndex &&
      settings.requireVehicleLookupBeforeNextStep === true
    ) {
      //Forces a DVLA lookup before proceeding to the next step.
      if (
        pageState.bookingData.vehicleChecked !==
        pageState.bookingData.vehicleReg
      ) {
        let vehicleReg = pageState.bookingData.vehicleReg || "";

        if (vehicleReg.length === 0) {
          setErrorState({
            errorField: "vehicleReg",
            errorMessage: "Enter the vehicle registration",
          });
          return false;
        }
        vehicleLookupTrigger();
        return false;
      }
    }

    if (index === settings.flightLookupStepIndex) {
      //Forces a flight lookup

      if (pageState.bookingData.flightData === null) {
        if (validateFlightDetails() === false) {
          return;
        }
        flightLookupTrigger();
        return false;
      }
    }

    if (stepChild.props.validate != null) {
      if (stepChild.props.validate() === false) {
        return false;
      }
    }

    if (stepChild.props.submit != null) {
      if ((await stepChild.props.submit()) === false) {
        return false;
      }
    }

    return true;
  };

  return (
    <div className={classes.root}>
      <ProgressIndicator
        visible={updatePassengerStatus.loading}
        title="Updating passenger details.."
      ></ProgressIndicator>
      <ProgressIndicator
        visible={updateVehicleStatus.loading}
        title="Updating vehicle details.."
      ></ProgressIndicator>
      <ProgressIndicator
        visible={updateFlightStatus.loading}
        title="Updating flight details.."
      ></ProgressIndicator>
      <ProgressIndicator
        visible={confirmOnlineCheckInStatus.loading}
        title="Confirming details.."
      ></ProgressIndicator>

      <AlertDialog
        open={errorState.errorMessage != null}
        title={errorState.errorTitle || "Error"}
        message={errorState.errorMessage}
        onDismiss={() => {
          setErrorState({
            errorTitle: null,
            errorField: null,
            errorMessage: null,
          });
        }}
      ></AlertDialog>
      <Fader in={true}>
        <div className="scale-7">{pageState.bannerText}</div>
      </Fader>
      <form noValidate autoComplete="off">
        <div className={classes.pageContainer}>
          <PageStepper
            onStepChanged={(child) => onStepChanged(child)}
            onStepNext={onStepNext}
            onFinalStep={onFinalStep}
            FinalStep={() => {
              return (
                <PrintOut
                  pageState={pageState}
                  qrCode={
                    confirmOnlineCheckInStatus.data.completeOnlineCheckIn.result
                      .qrCode.dataImage
                  }
                ></PrintOut>
              );
            }}
          >
            <Step
              title="Passenger"
              bannerText={
                <Typography className={classes.pageTitle}>
                  We just need a few
                  <span className={classes.pageTitleAlt}> details</span>
                </Typography>
              }
              icon={PassengerIcon}
              validate={() => {
                let title = pageState.bookingData.title || "";

                if (title.length === 0) {
                  setErrorState({
                    errorField: "title",
                    errorMessage: "Enter title of your name: (Mr/Ms/Mrs)",
                  });
                  return false;
                }

                let initial = pageState.bookingData.initial || "";

                if (initial.length === 0) {
                  setErrorState({
                    errorField: "initial",
                    errorMessage: "Enter the first letter of your name",
                  });
                  return false;
                }

                let lastName = pageState.bookingData.lastName || "";

                if (lastName.length === 0) {
                  setErrorState({
                    errorField: "lastName",
                    errorMessage: "Enter your last name",
                  });
                  return false;
                }

                let pax = pageState.bookingData.pax;
                if (pax === undefined || pax == null) {
                  pax = 0;
                }
                pax = parseInt(pax);
                if (pax === 0 || isNaN(pax)) {
                  setErrorState({
                    errorField: "pax",
                    errorMessage: "Enter the number of passengers",
                  });
                  return false;
                }

                let mobile = pageState.bookingData.mobile || "";

                if (mobile.length === 0) {
                  setErrorState({
                    errorField: "mobile",
                    errorMessage: "Enter your contact telephone number",
                  });
                  return false;
                }

                if (mobile.startsWith("+") === false && mobile.length > 0) {
                  mobile = "+44" + mobile.substring(1);
                }

                if (
                  new RegExp(/^\+[1-9]{1}[0-9]{3,16}$/).test(mobile) === false
                ) {
                  setErrorState({
                    errorField: "mobile",
                    errorMessage: "Invalid mobile number",
                  });
                  return false;
                }

                let email = pageState.bookingData.email || "";

                if (email.length === 0) {
                  setErrorState({
                    errorField: "email",
                    errorMessage: "Enter your email address",
                  });
                  return false;
                }

                if (
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                    email
                  ) === false
                ) {
                  setErrorState({
                    errorField: "email",
                    errorMessage: "Invalid email address",
                  });
                  return false;
                }

                return true;
              }}
              submit={async () => {
                let mobile = pageState.bookingData.mobile || "";
                if (mobile.startsWith("+") === false && mobile.length > 0) {
                  mobile = "+44" + mobile.substring(1);
                }

                const vars = {
                  variables: {
                    title: pageState.bookingData.title,
                    initial: pageState.bookingData.initial,
                    lastName: pageState.bookingData.lastName,
                    passengerCount: parseInt(pageState.bookingData.pax),
                    phoneNumber: mobile,
                    emailAddress: pageState.bookingData.email,
                  },
                };
                console.log(vars);
                var result = (await updatePassenger(vars)) || {};

                result = result || {
                  errors: ["Unable to passenger details."],
                };

                console.log(result);

                if (result.errors != null) {
                  setErrorState({
                    errorTitle: "Something went wrong!",
                    errorMessage: result.errors[0],
                  });
                  return false;
                } else if (result.data != null) {
                  if (result.data.confirmPassenger != null) {
                    if (result.data.confirmPassenger.result != null) {
                      if (
                        result.data.confirmPassenger.result.__typename != null
                      ) {
                        if (
                          result.data.confirmPassenger.result.__typename !==
                          "LeadPassenger"
                        ) {
                          setErrorState({
                            errorTitle: "Something went wrong!",
                            errorMessage:
                              errorMessages[
                                result.data.confirmPassenger.result.__typename
                              ] ||
                              result.data.confirmPassenger.result.__typename,
                          });
                          return false;
                        }
                      }
                    }
                  }
                } else {
                  return false;
                }
                return true;
              }}
            >
              <PassengerDetails
                classes={classes}
                bookingData={pageState.bookingData}
                errorField={errorState.errorField}
                onBookingDataChanged={(data) => onBookingDataChanged(data)}
              />
            </Step>
            <Step
              bannerText={
                <Typography className={classes.pageTitle}>
                  About your
                  <span className={classes.pageTitleAlt}> vehicle</span>
                </Typography>
              }
              title="Vehicle"
              icon={VehicleIcon}
              validate={() => {
                let vehicleReg = pageState.bookingData.vehicleReg || "";

                if (vehicleReg.length === 0) {
                  setErrorState({
                    errorField: "vehicleReg",
                    errorMessage: "Enter the vehicle registration",
                  });
                  return false;
                }

                let vehicleMake = pageState.bookingData.vehicleMake || "";

                if (vehicleMake.length === 0) {
                  setErrorState({
                    errorField: "vehicleMake",
                    errorMessage: "Enter the vehicle make",
                  });
                  return false;
                }

                let vehicleModel = pageState.bookingData.vehicleModel || "";

                if (vehicleModel.length === 0) {
                  setErrorState({
                    errorField: "vehicleModel",
                    errorMessage: "Enter the vehicle model",
                  });
                  return false;
                }

                let vehicleColour = pageState.bookingData.vehicleColour || "";

                if (vehicleColour.length === 0) {
                  setErrorState({
                    errorField: "vehicleColour",
                    errorMessage: "Enter the vehicle colour",
                  });
                  return false;
                }

                return true;
              }}
              submit={async () => {
                var result = await updateVehicle({
                  variables: {
                    registration: pageState.bookingData.vehicleReg,
                    make: pageState.bookingData.vehicleMake,
                    model: pageState.bookingData.vehicleModel,
                    colour: pageState.bookingData.vehicleColour,
                  },
                });

                result = result || {
                  errors: ["Unable to update vehicle details."],
                };

                console.log(result);
                if (result.errors != null) {
                  setErrorState({
                    errorTitle: "Something went wrong!",
                    errorMessage: result.errors[0],
                  });
                  return false;
                }
                return true;
              }}
            >
              <VehicleLookup
                classes={classes}
                bookingData={pageState.bookingData}
                errorField={errorState.errorField}
                onBookingDataChanged={(data) => onBookingDataChanged(data)}
                triggerLookup={attachVehicleLookupTrigger}
              />
            </Step>
            <Step
              title="Flight"
              bannerText={
                <Typography className={classes.pageTitle}>
                  Your return
                  <span className={classes.pageTitleAlt}> flight</span>
                </Typography>
              }
              icon={FlightIcon}
              validate={() => {
                return validateFlightDetails();
              }}
              submit={async () => {
                const vars = {
                  variables: {
                    designator: pageState.bookingData.flightNumber,
                    expectedLandingAt: pageState.bookingData.flightReturnDate.toISOString(),
                  },
                };
                console.log(vars);

                var result = await updateFlight(vars);

                result = result || {
                  errors: ["Unable to update flight details."],
                };

                if (result.errors != null) {
                  setErrorState({
                    errorTitle: "Something went wrong!",
                    errorMessage: result.errors[0],
                  });
                  return false;
                }

                result = await confirmOnlineCheckIn();
                console.log(result);

                result = result || { errors: ["Unable to complete."] };

                if (result.errors != null) {
                  setErrorState({
                    errorTitle: "Something went wrong!",
                    errorMessage: result.errors[0],
                  });
                  return false;
                }
                return true;
              }}
            >
              <FlightLookup
                classes={classes}
                bookingData={pageState.bookingData}
                errorField={errorState.errorField}
                onBookingDataChanged={(data) => onBookingDataChanged(data)}
                triggerLookup={attachFlightLookupTrigger}
              />
            </Step>
          </PageStepper>
        </div>
      </form>
    </div>
  );
};

export default withStyles(BaseStyles)(PageCIWizard);
