import React, { useState, useEffect, Fragment } from "react";
import CardWithLabel from "../../components/cardWithLabel";
import { TextField, Typography, Button } from "@material-ui/core";
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import ProgressIndicator from "../../components/progressIndicator";

const VehicleLookup = ({
  classes,
  bookingData,
  onBookingDataChanged,
  triggerLookup,
  errorField,
}) => {
  const [vehicleData, setVehicleDataState] = useState(bookingData);

  const [fetchVehicleData, { loading, data, error }] = useLazyQuery(gql`
    query($registration: String!) {
      vehicle: findVehicle(registration: $registration) {
        ... on Vehicle {
          registration
          make
          model
          colour
        }
      }
    }
  `);

  const setVehicleData = (vehicle) => {
    setVehicleDataState(vehicle);
    onBookingDataChanged(vehicle);
  };

  useEffect(() => {
    if (data !== undefined && loading === false && error == null) {
      const vehicle = Object.assign({}, vehicleData);
      const vehicleFound = data.vehicle.__typename === "Vehicle";
      data.vehicle.registration =
        data.vehicle.registration || vehicle.vehicleReg;

      if (vehicle.vehicleChecked !== data.vehicle.registration) {
        if (vehicleFound) {
          vehicle.vehicleMake = data.vehicle.make;
          vehicle.vehicleModel = data.vehicle.model;
          vehicle.vehicleColour = data.vehicle.colour;

          vehicle.vehicleChecked = data.vehicle.registration;
          vehicle.vehicleMatched = true;
          delete vehicle.vehicleError;
        } else {
          vehicle.vehicleMake = "";
          vehicle.vehicleModel = "";
          vehicle.vehicleColour = "";
          vehicle.vehicleChecked = vehicle.vehicleReg;
          vehicle.vehicleMatched = false;
          delete vehicle.vehicleError;
        }

        setVehicleData(vehicle);
      }
    } else if (error != null) {
      const vehicle = Object.assign({}, vehicleData);
      if (vehicle.vehicleChecked !== vehicle.vehicleReg) {
        vehicle.vehicleMake = "";
        vehicle.vehicleModel = "";
        vehicle.vehicleColour = "";
        vehicle.vehicleChecked = vehicle.vehicleReg;
        vehicle.vehicleMatched = false;
        vehicle.vehicleError = error.message;
        setVehicleData(vehicle);
      }
    }
  });

  const findVehicle = () => {
    fetchVehicleData({ variables: { registration: vehicleData.vehicleReg } });
  };

  triggerLookup(() => {
    findVehicle();
  });

  return (
    <ProgressIndicator
      visible={loading}
      title="Searching for vehicle details.."
    >
      <div className="row">
        <div className="col-12 col-xl-6">
          <CardWithLabel title="Find Vehicle">
            <TextField
              id="bk-vehicleReg"
              label="Registration"
              variant="outlined"
              error={errorField === "vehicleReg"}
              className={classes.input}
              value={(vehicleData.vehicleReg || "").trim().toUpperCase()}
              onChange={(e) => {
                const vehicle = Object.assign({}, vehicleData);
                vehicle.vehicleReg = e.currentTarget.value;
                setVehicleData(vehicle);
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  e.preventDefault();
                  e.stopPropagation();
                  findVehicle();
                }
              }}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
            />
            {(vehicleData.vehicleChecked !== vehicleData.vehicleReg ||
              vehicleData.vehicleChecked == null) && (
              <div className={classes.textRight}>
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  onClick={findVehicle}
                >
                  Find Vehicle
                </Button>
              </div>
            )}
          </CardWithLabel>
        </div>

        {vehicleData.vehicleChecked === vehicleData.vehicleReg &&
          vehicleData.vehicleChecked != null && (
            <div className="col-12 col-xl-6">
              <CardWithLabel
                title={
                  vehicleData.vehicleMatched === true
                    ? "Confirm Vehicle"
                    : "Vehicle Details"
                }
              >
                {(vehicleData.vehicleMatched === false ||
                  vehicleData.vehicleError !== undefined) && (
                  <div className={classes.subText}>
                    <Typography>
                      We could not locate your vehicle details. <br />
                      {vehicleData.vehicleError !== undefined && (
                        <Fragment>
                          <small style={{ color: "red" }}>
                            {vehicleData.vehicleError}
                          </small>
                          <br />
                          <br />
                        </Fragment>
                      )}
                      <b>Please check your registration number</b>
                      <br />
                      You can also complete the details manually using the form
                      below:
                    </Typography>
                  </div>
                )}

                <TextField
                  id="bk-vehicleMake"
                  label="Make"
                  variant="outlined"
                  error={errorField === "vehicleMake"}
                  value={vehicleData.vehicleMake || ""}
                  onChange={(e) => {
                    const vehicle = Object.assign({}, vehicleData);
                    vehicle.vehicleMake = e.currentTarget.value;
                    setVehicleData(vehicle);
                  }}
                  className={classes.input}
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                    },
                  }}
                />

                <TextField
                  id="bk-vehicleModel"
                  label="Model"
                  variant="outlined"
                  error={errorField === "vehicleModel"}
                  value={vehicleData.vehicleModel || ""}
                  onChange={(e) => {
                    const vehicle = Object.assign({}, vehicleData);
                    vehicle.vehicleModel = e.currentTarget.value;
                    setVehicleData(vehicle);
                  }}
                  className={classes.input}
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                    },
                  }}
                />

                <TextField
                  id="bk-vehicleColour"
                  label="Colour"
                  variant="outlined"
                  error={errorField === "vehicleColour"}
                  value={vehicleData.vehicleColour || ""}
                  onChange={(e) => {
                    const vehicle = Object.assign({}, vehicleData);
                    vehicle.vehicleColour = e.currentTarget.value;
                    setVehicleData(vehicle);
                  }}
                  className={classes.input}
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                    },
                  }}
                />
              </CardWithLabel>
            </div>
          )}
      </div>
    </ProgressIndicator>
  );
};

export { VehicleLookup };
