import { getAuthenticationToken } from "../api";
import settings from "../settings";
import { navigationTracker } from "../nativationTracker";

const REQUEST_AUTH_STARTED = "REQUEST_AUTH_STARTED";
const REQUEST_AUTH_SUCCESS = "REQUEST_AUTH_SUCCESS";
const REQUEST_AUTH_ERROR = "REQUEST_AUTH_ERROR";
const REQUEST_AUTH_RESET = "REQUEST_AUTH_RESET";

const initialState = {
  loading: false,
  err: null,
  token: null,
  gqlUrl: null,
  bookingReference: null,
  leadPassengerLastName: null,
};

export default {
  triggers: {
    request: ({ bookingReference, leadPassengerLastName, history }) => {
      return async (dispatch) => {
        dispatch({
          type: REQUEST_AUTH_STARTED,
        });

        try {
          const token = await getAuthenticationToken(
            settings.authUrl,
            bookingReference,
            leadPassengerLastName
          );

          navigationTracker(history, "/bookingDetails/");

          dispatch({
            type: REQUEST_AUTH_SUCCESS,
            payload: {
              token,
              bookingReference,
              leadPassengerLastName,
            },
          });
        } catch (ex) {
          dispatch({
            type: REQUEST_AUTH_ERROR,
            payload: ex,
          });
        }
      };
    },
    reset: () => {
      return (dispatch) => {
        dispatch({
          type: REQUEST_AUTH_RESET,
        });
      };
    },
  },
  reducer: (state = initialState, action) => {
    switch (action.type) {
      case REQUEST_AUTH_STARTED:
        return {
          ...state,
          loading: true,
          err: null,
          token: null,
          bookingReference: null,
          leadPassengerLastName: null,
        };
      case REQUEST_AUTH_SUCCESS:
        return {
          ...state,
          loading: false,
          token: action.payload.token,
          bookingReference: action.payload.bookingReference,
          leadPassengerLastName: action.payload.leadPassengerLastName,
          gqlUrl: settings.gqlUrl,
        };
      case REQUEST_AUTH_ERROR:
        return { ...state, loading: false, err: action.payload };
      case REQUEST_AUTH_RESET:
        return initialState;
      default:
        return state;
    }
  },
};
