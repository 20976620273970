import React, { useEffect } from "react";

import Fab from "@material-ui/core/Fab";

import NextIcon from "@material-ui/icons/Check";
import BackIcon from "@material-ui/icons/ChevronLeft";

import FinishIcon from "@material-ui/icons/Done";
import { withStyles } from "@material-ui/core";
import { BaseStyles } from "../../themes/base";
import { withRouter } from "react-router-dom";
import { navigationTracker } from "../../nativationTracker";
/*
import { StepConnector } from "@material-ui/core";
import Check from "@material-ui/icons/Check";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
*/

const PageStepper = ({
  children,
  onStepChanged,
  onStepNext,
  onFinalStep,
  FinalStep,
  classes,
  history,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [activeStep, setActiveStepState] = React.useState(0);

  const setActiveStep = (index) => {
    if (index === 0) {
      navigationTracker(history, "/bookingDetails/");
    } else if (index === 1) {
      navigationTracker(history, "/vehicleDetails/");
    } else if (index === 2) {
      navigationTracker(history, "/flightDetails/");
    } else if (index === 3) {
      navigationTracker(history, "/finished/");
    }

    setActiveStepState(index);

    if (onStepChanged) {
      onStepChanged(children[index]);
    }
    if (index === children.length) {
      if (onFinalStep) {
        onFinalStep();
      }
    }
  };
  const handleNext = async () => {
    if (onStepNext != null) {
      if ((await onStepNext(activeStep, children[activeStep])) === false) {
        return;
      }
    }

    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  /*
  const MyStepConnector = withStyles({
    alternativeLabel: {
      top: 22,
    },
    active: {
      "& $line": {
        backgroundImage:
          "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
      },
    },
    completed: {
      "& $line": {
        backgroundImage:
          "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
      },
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: "#eaeaf0",
      borderRadius: 1,
    },
  })(StepConnector);

  const useStepIconStyles = makeStyles({
    root: {
      backgroundColor: "#ccc",
      zIndex: 1,
      color: "#fff",
      width: 50,
      height: 50,
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
    },
    active: {
      backgroundImage:
        "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    },
    completed: {
      backgroundImage:
        "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    },
  });


  function MyStepConnectorIcon(props) {
    const classes = useStepIconStyles();
    const { active, completed } = props;
    const Icon = children[props.icon - 1].props.icon || Check;

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active || completed,
        })}
      >
        {completed ? (
          <div className={classes.circle}>
            <Check />
          </div>
        ) : (
          <div className={classes.circle}>
            <Icon />
          </div>
        )}
      </div>
    );
  }*/

  return (
    <div className={classes.root}>
      {/*
        <Stepper alternativeLabel activeStep={activeStep}>
        {React.Children.map(children, (child, childKey) => {
          return (
            <Step key={childKey} connector={<MyStepConnector />}>
              <StepLabel StepIconComponent={MyStepConnectorIcon}>
                {child.props.title}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
        */}

      <div>
        {activeStep === children.length ? (
          <FinalStep></FinalStep>
        ) : (
          <div>
            {children[activeStep]}
            <div className={classes.fabBar}>
              {activeStep > 0 && (
                <Fab
                  className={classes.fab}
                  color="secondary"
                  aria-label="Back"
                  onClick={handleBack}
                >
                  <BackIcon />
                </Fab>
              )}
              {activeStep < children.length - 1 && (
                <Fab
                  className={classes.fab}
                  color="primary"
                  aria-label="Next"
                  onClick={handleNext}
                >
                  <NextIcon />
                </Fab>
              )}

              {activeStep === children.length - 1 && (
                <Fab
                  className={classes.fab}
                  color="primary"
                  aria-label="Finish"
                  onClick={handleNext}
                >
                  <FinishIcon />
                </Fab>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(withStyles(BaseStyles)(PageStepper));
