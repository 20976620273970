import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import store from "../../store";

import CardWithLabel from "../../components/cardWithLabel";
import ProgressIndicator from "../progressIndicator";

import { TextField, Button, withStyles } from "@material-ui/core";

import { AlertDialog } from "../dialogs";

import { BaseStyles } from "../../themes/base";
import { withRouter } from "react-router-dom";
import { navigationTracker } from "../../nativationTracker";

const BookingLookup = ({
  children,
  requestAuth,
  resetAuth,
  token,
  loading,
  err,
  classes,
  history,
}) => {
  const [bookingReference, setBookingReference] = useState("");
  const [leadPassengerLastName, setLeadPassengerLastName] = useState("");

  const resetState = () => {
    navigationTracker(history, "/");
    resetAuth();
  };

  if (window.notFirstLoad !== true) {
    window.notFirstLoad = true;
    resetState();
  }

  const submitState = () => {
    if (bookingReference.length === 0 || leadPassengerLastName.length === 0) {
      return;
    }
    requestAuth({
      bookingReference: (bookingReference || "").trim(),
      leadPassengerLastName: (leadPassengerLastName || "").trim(),
      history: history,
    });
  };
  //console.log(token);
  return (
    <ProgressIndicator visible={loading} title="Locating booking..">
      <Fragment>
        {token != null && children}
        {token == null && (
          <Fragment>
            <AlertDialog
              title="Booking Not Found"
              message="We could not locate your booking details."
              open={err != null}
              onDismiss={resetState}
            ></AlertDialog>

            <div className="container">
              <div className="row">
                <div className="col" style={{ textAlign: "center" }}>
                  <div
                    style={{
                      maxWidth: 600,
                      display: "inline-block",
                      marginTop: "15%",
                    }}
                  >
                    <CardWithLabel
                      altTitle={true}
                      title={
                        <Fragment>
                          <span>Find your</span>
                          <span>&nbsp;</span>
                          <span className={classes.cardTitleHighlight}>
                            booking
                          </span>
                        </Fragment>
                      }
                    >
                      <TextField
                        label="Booking Reference"
                        variant="outlined"
                        className={classes.input}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused,
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: classes.cssOutlinedInput,
                            focused: classes.cssFocused,
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                        value={bookingReference}
                        onChange={(e) => {
                          setBookingReference(e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.keyCode === 13) {
                            e.preventDefault();
                            e.stopPropagation();
                            submitState();
                          }
                        }}
                      />

                      <TextField
                        label="Surname"
                        variant="outlined"
                        className={classes.input}
                        value={leadPassengerLastName}
                        onChange={(e) => {
                          setLeadPassengerLastName(e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.keyCode === 13) {
                            e.preventDefault();
                            e.stopPropagation();
                            submitState();
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused,
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: classes.cssOutlinedInput,
                            focused: classes.cssFocused,
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />

                      <Button
                        size="large"
                        color="primary"
                        variant="contained"
                        onClick={submitState}
                      >
                        Find Booking
                      </Button>
                    </CardWithLabel>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </Fragment>
    </ProgressIndicator>
  );
};

export default withRouter(
  connect(
    (state) => ({
      token: state.requestAuth.token,
      loading: state.requestAuth.loading,
      err: state.requestAuth.err,
    }),
    {
      requestAuth: store.triggers.requestAuth.request,
      resetAuth: store.triggers.requestAuth.reset,
    }
  )(withStyles(BaseStyles)(BookingLookup))
);
