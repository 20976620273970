import React from "react";
import { Fade } from "@material-ui/core";

const FaderChild = (props) => {
  return <div {...props}>{props.children}</div>;
};

const Fader = (props) => {
  return (
    <Fade {...props}>
      <FaderChild children={props.children} />
    </Fade>
  );
};

export { Fader };
