import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { TextField, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import destinations from "../../../destinations.json";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const FlightDestinationPicker = ({
  open,
  onDismiss,
  onDestinationSelected,
  classes,
}) => {
  const [searchText, setSearchText] = useState("");

  let items = [];

  let searchTextUpper = searchText.toUpperCase();

  for (let i = 0; i < destinations.length; i++) {
    const destination = destinations[i];

    if (items.length > 50) {
      break;
    }
    if (searchText.length > 0) {
      if (
        destination.city.toUpperCase().indexOf(searchTextUpper) !== -1 ||
        destination.iata.toUpperCase() === searchTextUpper
      ) {
        items.push(destination);
      }
    }
  }

  items.sort(function (a, b) {
    if (a.city < b.city) {
      return -1;
    }
    if (a.city > b.city) {
      return 1;
    }
    return 0;
  });

  return (
    <Dialog
      open={open}
      fullWidth={true}
      onClose={onDismiss}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" onClose={onDismiss}>
        Select Flight Destination
      </DialogTitle>

      <div style={{ padding: 20 }}>
        <TextField
          autoFocus
          label="Find destination"
          variant="outlined"
          fullWidth={true}
          value={searchText}
          onChange={(e) => {
            setSearchText(e.currentTarget.value.toUpperCase());
          }}
          InputLabelProps={{
            classes: {
              root: classes.cssLabel,
              focused: classes.cssFocused,
            },
          }}
          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              focused: classes.cssFocused,
              notchedOutline: classes.notchedOutline,
            },
          }}
        />
      </div>

      <DialogContent style={{ height: "60vh" }}>
        {searchText.length > 0 && items.length === 0 && (
          <Typography>No results found</Typography>
        )}

        <List>
          {items.map((item, key) => {
            return (
              <ListItem
                style={{
                  border: "solid 1px lightgray",
                  borderRadius: 5,
                  marginBottom: 5,
                  cursor: "pointer",
                }}
                key={key}
                onClick={() => {
                  onDestinationSelected(item);
                }}
              >
                <ListItemText
                  primary={item.name}
                  secondary={`(${item.iata}) ${item.city}`}
                />
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export { FlightDestinationPicker };
