import React from "react";
import CardWithLabel from "../../components/cardWithLabel";
import { TextField } from "@material-ui/core";

const PassengerDetails = ({
  classes,
  bookingData,
  onBookingDataChanged,
  errorField,
}) => {
  return (
    <div className="row">
      <div className="col-12 col-xl-6">
        <CardWithLabel title="Passenger details">
          <TextField
            id="bk-title"
            label="Title"
            variant="outlined"
            error={errorField === "title"}
            className={classes.input}
            value={bookingData.title || ""}
            onChange={(e) => {
              const booking = Object.assign({}, bookingData);
              booking.title = e.currentTarget.value;
              onBookingDataChanged(booking);
            }}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              },
            }}
          />
          <TextField
            id="bk-initial"
            label="Initial"
            variant="outlined"
            className={classes.input}
            value={bookingData.initial || ""}
            error={errorField === "initial"}
            onChange={(e) => {
              const booking = Object.assign({}, bookingData);
              booking.initial = e.currentTarget.value
                .substring(0, 1)
                .toUpperCase();

              if (booking.initial.length > 0) {
                if (
                  !(
                    booking.initial.toString().charCodeAt(0) >= 65 &&
                    booking.initial.toString().charCodeAt(0) <= 90
                  )
                ) {
                  return;
                }
              }
              onBookingDataChanged(booking);
            }}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              },
            }}
          />
          <TextField
            id="bk-lastName"
            label="Last Name"
            type=""
            variant="outlined"
            error={errorField === "lastName"}
            className={classes.input}
            value={bookingData.lastName || ""}
            onChange={(e) => {
              const booking = Object.assign({}, bookingData);
              booking.lastName = e.currentTarget.value;
              onBookingDataChanged(booking);
            }}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              },
            }}
          />
          <TextField
            id="bk-pax"
            label="Passengers"
            type=""
            error={errorField === "pax"}
            variant="outlined"
            className={classes.input}
            value={bookingData.pax || ""}
            onChange={(e) => {
              const booking = Object.assign({}, bookingData);
              booking.pax = e.currentTarget.value;
              if (booking.pax > 99) {
                booking.pax = 99;
              }
              onBookingDataChanged(booking);
            }}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              },
              type: "number",
            }}
          />
        </CardWithLabel>
      </div>
      <div className="col-12 col-xl-6">
        <CardWithLabel title="Contact details">
          <TextField
            id="bk-mobile"
            label="Mobile"
            type=""
            error={errorField === "mobile"}
            variant="outlined"
            className={classes.input}
            value={bookingData.mobile || ""}
            onChange={(e) => {
              const booking = Object.assign({}, bookingData);

              booking.mobile = e.currentTarget.value;

              onBookingDataChanged(booking);
            }}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              },
            }}
          />
          <TextField
            id="bk-email"
            label="Email"
            variant="outlined"
            error={errorField === "email"}
            className={classes.input}
            value={bookingData.email || ""}
            onChange={(e) => {
              const booking = Object.assign({}, bookingData);
              booking.email = e.currentTarget.value;
              onBookingDataChanged(booking);
            }}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              },
            }}
          />
        </CardWithLabel>
      </div>
    </div>
  );
};

export { PassengerDetails };
