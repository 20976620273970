const navigationTracker = (history, path) => {
  var site = window.location.href.split("/")[3];
  var url = `/${site}${path}`;
  history.push(url);
  /* if (typeof window.ga === "function") {
    window.ga("set", "page", url);
    window.ga("send", "pageview", url);
  }*/
};

export { navigationTracker };
