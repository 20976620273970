import request from "request";

const getAuthenticationToken = async (
  uri,
  bookingReference,
  leadPassengerLastName
) => {
  bookingReference = bookingReference || "";
  leadPassengerLastName = leadPassengerLastName || "";

  return new Promise((accept, reject) => {
    request(
      uri,
      {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ bookingReference, leadPassengerLastName }),
      },
      (err, res, body) => {
        try {
          body = JSON.parse(body);
        } catch (ex) {
          body = {};
        }

        const token = body.token;

        if (token === undefined) {
          reject(body.message || "Unknown");
          return;
        }
        accept(token);
      }
    );
  });
};

export { getAuthenticationToken };
