import { combineReducers } from "redux";
import requestAuth from "./requestAuth";

export default {
  triggers: {
    requestAuth: requestAuth.triggers,
  },
  rootReducer: combineReducers({
    requestAuth: requestAuth.reducer,
  }),
};
