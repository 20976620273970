import React, { useRef } from "react";
import Fab from "@material-ui/core/Fab";
import PrintIcon from "@material-ui/icons/Print";
import { Typography, withStyles } from "@material-ui/core";
import CardWithLabel from "../../components/cardWithLabel";
import { BaseStyles } from "../../themes/base";

const PrintOut = ({ classes, qrCode }) => {
  const iframe = useRef();
  const printableArea = useRef();

  const print = () => {
    var content = printableArea.current;
    var pri = iframe.current.contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  };

  return (
    <div>
      <CardWithLabel title="Confirmation QR Code">
        <div ref={printableArea} style={{ backgroundColor: "#fff" }}>
          <Typography className={classes.instructions}>
            <b>
              You will need this QR when you arrive onsite to complete check-in.
            </b>
          </Typography>
          <Typography className={classes.instructions}>
            Your code has been emailed to you.
          </Typography>
          <div style={{ textAlign: "center" }}>
            <img
              src={qrCode}
              alt="QR Code"
              style={{ display: "inline-block" }}
            />
          </div>
        </div>
      </CardWithLabel>
      <iframe
        ref={iframe}
        title="Print"
        style={{
          width: 0,
          height: 0,
          position: "fixed",
          left: -1000,
          top: -1000,
        }}
      ></iframe>

      <div className={classes.fabBar}>
        <Fab
          className={classes.fab}
          color="primary"
          aria-label="Print"
          onClick={() => {
            print();
          }}
        >
          <PrintIcon />
        </Fab>
      </div>
    </div>
  );
};

export default withStyles(BaseStyles)(PrintOut);
